import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {MerchantAccountsList} from '@/pages/merchant-accounts/merchant-accounts-list';
import {useStore} from '@/store';
import {XCircleIcon} from '@heroicons/react/24/outline';
import {Plural, Trans, t} from '@lingui/macro';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  TableSortValue,
  Typography,
  useDrawerState,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import {z} from 'zod';
import {AddMerchantAccount} from './actions/add-merchant-account';
import {EditAndResendInvitation} from './actions/edit-and-resend-invitation';
import {SendMerchantRegistrationInvite} from './actions/send-merchant-registration-invite';
import {UpdateBankAccount} from './actions/update-bank-account';
import {UpdateMerchantCustomerSupport} from './actions/update-merchant-csutomer-support';
import {UpdateMerchantEmailNotifications} from './actions/update-merchant-email-notifications';
import {UpdateMerchantSurchargeViaAdditionalCommissionConfiguration} from './actions/update-merchant-surcharge-via-additional-commission-configuration';
import {MerchantAccountsStats} from './merchant-accounts-stats';

const breadcrumbs = () => [{name: t`Merchant Accounts`, href: '#', current: true}];

export const MerchantAccounts = () => {
  const searchParamsSchema = z.object({
    status: z
      .array(z.nativeEnum(MerchantAccountPublicStatus))
      .or(z.string().transform(str => str.split(',').map(s => s as MerchantAccountPublicStatus)))
      .optional(),
  });

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const status = typedSearchParams?.status;

  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const navigate = useNavigate();
  const merchantAccountsStats = trpc.merchantAccount.getMerchantAccountsStats.useQuery(undefined, {
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
  });
  const merchantAccountsList = trpc.merchantAccount.getMerchantAccountsList.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      ...(status?.length && {where: {status}}),
    },
    {refetchOnWindowFocus: true, refetchInterval: 5000}
  );

  // When filter or sorting changes we need to reset pagination
  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const {
    isOpen: isAddMerchantAccountFormVisible,
    open: openAddMerchantAccountForm,
    close: closeAddMerchantAccountForm,
  } = useDrawerState({onSuccessHandler: () => {}});

  const {
    data: editAndResendRow,
    on: openEditAndResendPanel,
    off: closeEditAndResendPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();
  const {
    data: updateCustomerSupportRow,
    on: openUpdateCustomerSupportPanel,
    off: closeUpdateCustomerSupportPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();
  const {
    data: updateEmailNotificationsRow,
    on: openUpdateEmailNotificationsPanel,
    off: closeUpdateEmailNotificationsPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();
  const {
    data: updateBankAccountRow,
    on: openUpdateBankAccountPanel,
    off: closeUpdateBankAccountPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();
  const {
    data: updateSurchargeConfigurationRow,
    on: openSurchargeConfigurationPanel,
    off: closeSurchargeConfigurationPanel,
  } = useToggleWithData<MerchantAccountsListItem | MerchantAccountDetailsOutput | null>();

  const inviteDrawer = useDrawerState({onSuccessHandler: () => {}});

  const {tenant, authProfile} = useStore();

  const handleSelectStatus = useCallback(
    (selectedStatus: MerchantAccountPublicStatus[]) => {
      setTypedSearchParams(
        status &&
          status.length === selectedStatus.length &&
          selectedStatus.every(element => status.includes(element))
          ? {status: []}
          : {status: selectedStatus}
      );
    },
    [setTypedSearchParams, status]
  );

  const handleDetailsOpen = useCallback(
    (merchantAccountRow: MerchantAccountsListItem | null) => {
      if (merchantAccountRow?.merchantAccount?.id) {
        navigate(
          generatePath(RoutePath.MERCHANT_ACCOUNT_DETAILS, {
            merchantAccountId: merchantAccountRow.merchantAccount.id,
          })
        );
      }
    },
    [navigate]
  );

  const handleClearFilters = useCallback(() => {
    setTypedSearchParams({});
  }, []);

  const filtersAmount = status?.length || 0;

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex justify-between">
          <Trans>Merchant Accounts</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          {filtersAmount >= 1 && (
            <div className="flex items-center justify-between space-x-2 overflow-hidden rounded-md bg-white px-6 py-5 font-semibold shadow ring-1 ring-gray-300 sm:p-1.5 sm:text-sm">
              <span className="pl-0.5">
                <Trans>
                  {filtersAmount}{' '}
                  <Plural value={filtersAmount} one="filter applied" other="filters applied" />
                </Trans>
              </span>
              <button type="button" className="shrink-0" onClick={handleClearFilters}>
                <XCircleIcon className="w-5 h-5 text-gray-400 hover:text-gray-600" />
              </button>{' '}
            </div>
          )}
          {!tenant?.features?.disableAddingMerchantInPortal &&
            authProfile.data?.isOrganizationAdmin && (
              <div className="flex space-x-2">
                <Button
                  type="button"
                  variant="primary"
                  size="md"
                  className="w-fit"
                  onClick={openAddMerchantAccountForm}
                >
                  <Trans>Add Merchant Account</Trans>
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  size="md"
                  className="w-fit"
                  onClick={inviteDrawer.open}
                >
                  <Trans>Invite</Trans>
                </Button>
              </div>
            )}
        </div>
      </div>
      <MerchantAccountsStats
        merchantAccountStats={merchantAccountsStats.data}
        isLoading={merchantAccountsStats.isLoading}
        handleSelectStatus={handleSelectStatus}
        status={status || []}
      />
      <div className="mt-4">
        <MerchantAccountsList
          merchantAccountsList={merchantAccountsList.data}
          merchantAccountsRefetch={merchantAccountsList.refetch}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetails={handleDetailsOpen}
          openEditAndResendPanel={openEditAndResendPanel}
          openUpdateCustomerSupportPanel={openUpdateCustomerSupportPanel}
          openUpdateEmailNotificationsPanel={openUpdateEmailNotificationsPanel}
          openUpdateBankAccountPanel={openUpdateBankAccountPanel}
          openUpdateSurchargeConfigurationPanel={
            tenant?.features?.enableSurchargesViaAdditionalCommission &&
            authProfile.data?.isOrganizationAdmin
              ? openSurchargeConfigurationPanel
              : undefined
          }
          isLoading={merchantAccountsList.isLoading}
        />
      </div>
      <AddMerchantAccount
        isOpen={isAddMerchantAccountFormVisible}
        onCancel={closeAddMerchantAccountForm}
      />
      <SendMerchantRegistrationInvite
        isOpen={inviteDrawer.isOpen}
        onCancel={inviteDrawer.close}
        onSuccess={inviteDrawer.onSuccess}
      />
      {editAndResendRow && (
        <EditAndResendInvitation
          merchantAccountRow={editAndResendRow}
          onClose={closeEditAndResendPanel}
          isOpen={!!editAndResendRow}
          refetchMerchantAccountsList={merchantAccountsList.refetch}
        />
      )}
      {updateCustomerSupportRow && (
        <UpdateMerchantCustomerSupport
          merchantAccountRow={updateCustomerSupportRow}
          onClose={closeUpdateCustomerSupportPanel}
          isOpen={!!updateCustomerSupportRow}
          triggerRefetch={merchantAccountsList.refetch}
        />
      )}
      {updateBankAccountRow && (
        <UpdateBankAccount
          merchantAccountRow={updateBankAccountRow}
          onClose={closeUpdateBankAccountPanel}
          isOpen={!!updateBankAccountRow}
          triggerRefetch={merchantAccountsList.refetch}
        />
      )}
      {updateEmailNotificationsRow && (
        <UpdateMerchantEmailNotifications
          merchantAccountRow={updateEmailNotificationsRow}
          onClose={closeUpdateEmailNotificationsPanel}
          isOpen={!!updateEmailNotificationsRow}
          triggerRefetch={merchantAccountsList.refetch}
        />
      )}
      {!!updateSurchargeConfigurationRow && (
        <UpdateMerchantSurchargeViaAdditionalCommissionConfiguration
          merchantAccountRow={updateSurchargeConfigurationRow}
          isOpen={!!updateSurchargeConfigurationRow}
          triggerRefetch={merchantAccountsList.refetch}
          onClose={closeSurchargeConfigurationPanel}
        />
      )}
    </div>
  );
};
