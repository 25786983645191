import {I18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import type {
  MerchantAccountsListItem,
  MerchantAccountsListOutput,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {MerchantAccountPublicStatus, TableColumnSize, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  TableCheckboxFilter,
  TruncatedText,
  getLocalizedStatusMap,
  merchantAccountStatusToColor,
} from '@zentact/ui-tailwind';
import {MerchantAccountActions, MerchantAccountActionsProps} from './merchant-account-actions';

const columnsHelper = createColumnHelper<MerchantAccountsListOutput['rows'][0]>();

export const getMerchantAccountColumns = ({
  refetchMerchantAccountsList,
  i18n,
  openDetails,
  openEditAndResendPanel,
  openUpdateCustomerSupportPanel,
  openUpdateEmailNotificationsPanel,
  openUpdateBankAccountPanel,
  openUpdateSurchargeConfigurationPanel,
}: {
  refetchMerchantAccountsList: () => void;
  i18n: I18n;
  openDetails: (row: MerchantAccountsListItem | null) => void;
  openEditAndResendPanel: MerchantAccountActionsProps['openEditAndResendPanel'];
  openUpdateCustomerSupportPanel: MerchantAccountActionsProps['openUpdateCustomerSupportPanel'];
  openUpdateEmailNotificationsPanel: MerchantAccountActionsProps['openUpdateEmailNotificationsPanel'];
  openUpdateBankAccountPanel: MerchantAccountActionsProps['openUpdateBankAccountPanel'];
  openUpdateSurchargeConfigurationPanel: MerchantAccountActionsProps['openUpdateSurchargeConfigurationPanel'];
}) => {
  const statusLabelMap = getLocalizedStatusMap(i18n);

  return [
    columnsHelper.accessor('merchantAccount.businessName', {
      cell: row => <TruncatedText text={row.getValue() || i18n._('N/A')} />,
      header: () => <Trans>Merchant Account</Trans>,
    }),
    columnsHelper.accessor(
      row => row.inviteeEmail ?? row.merchantAccount?.brandConfiguration?.supportEmail,
      {
        id: 'contact',
        cell: info => <TruncatedText text={info.getValue() || '-'} maxLength={25} />,
        header: () => <Trans>Contact</Trans>,
        meta: {
          collapseAt: 'sm',
        },
      }
    ),
    columnsHelper.accessor('status', {
      cell: info => (
        <FlatPillWithDot
          color={merchantAccountStatusToColor[info.getValue()] || 'blue'}
          label={getLocalizedStatusMap(i18n)[info.getValue()] || info.getValue()}
        />
      ),
      meta: {
        filter: {
          filterId: 'status',
          renderFilter: ({onChange, filterId, filterValues}) => {
            return (
              <TableCheckboxFilter
                filterValues={filterValues}
                filterId={filterId}
                onChange={onChange}
                elements={Object.keys(merchantAccountStatusToColor).map(status => ({
                  element: (
                    <FlatPillWithDot
                      color={
                        merchantAccountStatusToColor[status as MerchantAccountPublicStatus] ||
                        'blue'
                      }
                      label={statusLabelMap[status as MerchantAccountPublicStatus]}
                    />
                  ),
                  key: status,
                }))}
              />
            );
          },
        },
      },
      header: () => <Trans>Status</Trans>,
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('updatedAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Last Active</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        collapseAt: 'md',
      },
      size: TableColumnSize.S,
    }),
    columnsHelper.display({
      id: 'actions',
      cell: props => (
        <div className="flex justify-center">
          <MerchantAccountActions
            merchantAccountRow={props.row.original}
            registrationSessionId={props.row.original.sessionId}
            userId={props.row.original.userId}
            businessName={props.row.original.merchantAccount.businessName || ''}
            refetchMerchantAccountsList={refetchMerchantAccountsList}
            openDetails={openDetails}
            openEditAndResendPanel={openEditAndResendPanel}
            openUpdateCustomerSupportPanel={openUpdateCustomerSupportPanel}
            openUpdateEmailNotificationsPanel={openUpdateEmailNotificationsPanel}
            openUpdateBankAccountPanel={openUpdateBankAccountPanel}
            openUpdateSurchargeConfigurationPanel={openUpdateSurchargeConfigurationPanel}
            viewType="dots-button"
          />
        </div>
      ),
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
