// Public facing merchant account status that is based on a combination of a merchant account and its registration status
export enum MerchantAccountPublicStatus {
  // Account statuses after onboarding
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  REJECTED = 'REJECTED',
  // Account statuses before boarding
  INVITE_ACCEPTED = 'INVITE_ACCEPTED',
  APPLICATION_REVOKED = 'APPLICATION_REVOKED',
  INITIATED = 'INITIATED', // created without sending an inivte
  INVITED = 'INVITED', // created with invite sending or invite was sent later
  INVITE_EXPIRED = 'INVITE_EXPIRED',
  INVITE_REVOKED = 'INVITE_REVOKED',
  APPLICATION_IN_PROGRESS = 'APPLICATION_IN_PROGRESS',
  APPLICATION_IN_REVIEW = 'APPLICATION_IN_REVIEW',
  REVIEW_REJECTED = 'REVIEW_REJECTED',
  NEEDS_ORGANIZATION = 'NEEDS_ORGANIZATION',
  ASSIGNING_ORGANIZATION = 'ASSIGNING_ORGANIZATION',
  ADDITIONAL_DATA_REQUIRED = 'ADDITIONAL_DATA_REQUIRED',
  CLOSING = 'CLOSING',
}
