import {I18n} from '@lingui/core';
import {useLingui} from '@lingui/react';
import {PaymentItemWithEvents} from '@zentact/api/src/trpc/routers/paymentRouter';
import {CurrencyCode, LocaleCode, formatAmount, formatLocaleDate} from '@zentact/common';
import {PaymentEventType} from '@zentact/db';
import {displayPaymentStatusMap, paymentStatusToColor} from '..';
import {FlatPillWithDot} from '../..';

export const getPaymentEventTypeTranslations = (i18n: I18n, paymentEvent: PaymentEventType) => {
  const translations: {[Key in PaymentEventType]: string} = {
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    PENDING: i18n._('Pending'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    AUTHORIZATION: i18n._('Authorization'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    AUTHORIZATION_ADJUSTMENT: i18n._('Authorization Adjusted'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    AUTHORIZATION_FAILED: i18n._('Authorization Failed'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    SETTLEMENT: i18n._('Settlement'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    SETTLEMENT_FAILED: i18n._('Settlement Failed'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    VOID: i18n._('Void'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    VOID_REQUESTED: i18n._('Void Requested'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    VOID_FAILED: i18n._('Void Failed'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    REFUND_REQUESTED: i18n._('Refund Requested'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    REFUND: i18n._('Refund'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    REFUND_FAILED: i18n._('Refund Failed'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    PARTIAL_REFUND_REQUESTED: i18n._('Partial Refund Requested'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    PARTIAL_REFUND: i18n._('Partial Refund'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    PARTIAL_REFUND_FAILED: i18n._('Partial Refund Failed'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    UNREFERENCED_REFUND: i18n._('Unreferenced Refund'),
    // biome-ignore lint/style/useNamingConvention: DB Mapping convention
    CHARGEBACK: i18n._('Chargeback'),
  };
  return translations[paymentEvent];
};

type Props = {
  payment: PaymentItemWithEvents;
};

export const PaymentEventsList = ({payment}: Props) => {
  const {i18n} = useLingui();
  return (
    <ul>
      {payment.paymentEvents.map((event, i) => (
        <li key={`payment-${i}`} className="flex text-sm leading-6 ">
          <div className="flex flex-col items-center mr-5">
            <div className="mt-[2px] mb-[5px] h-[20px] w-[20px] rounded-full border-solid border-[2px] bg-white border-gray-200 shadow-sm" />
            {i < payment.paymentEvents.length - 1 && (
              <div className="flex-1 w-[2px] bg-gray-200 mb-[3px]" />
            )}
          </div>
          <div className="grid w-full pb-5 md:grid-cols-[minmax(200px,_300px)_minmax(200px,_300px)_minmax(150px,_300px)_minmax(0,_1fr)]">
            <div className="flex md:col-span-1">
              <div className="font-medium text-gray-900 whitespace-nowrap">
                {getPaymentEventTypeTranslations(i18n, event.type)}
              </div>
            </div>
            <div className="text-gray-700 md:col-span-1">
              <div className="flex whitespace-nowrap gap-x-2">
                {i18n._('Status: ')}{' '}
                <FlatPillWithDot
                  color={paymentStatusToColor[event.status] || 'blue'}
                  label={displayPaymentStatusMap[event.status] ?? event.status}
                />
              </div>
              {(event.type === 'PARTIAL_REFUND' ||
                event.type === 'REFUND' ||
                event.type === 'PARTIAL_REFUND_REQUESTED' ||
                event.type === 'REFUND_REQUESTED') && (
                <div className="whitespace-nowrap">
                  {i18n._('Amount: ')}
                  {formatAmount(
                    event.amount,
                    i18n.locale as LocaleCode,
                    event.currency as CurrencyCode
                  )}
                </div>
              )}
              {event.pspReferenceId && (
                <div className="whitespace-nowrap">
                  {i18n._('Reference: ')} {event.pspReferenceId}
                </div>
              )}
            </div>
            <div className="font-medium text-gray-400 md:text-right md:col-span-1 whitespace-nowrap">
              {formatLocaleDate(event.createdAt)}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
