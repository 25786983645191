import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountPublicStatus,
  PaymentsFilters,
  capitalizeCustomAttribute,
} from '@zentact/common';
import {MerchantAccountStatus} from '@zentact/db';
import {useCallback, useEffect, useState} from 'react';
import {MerchantAccountsPickerFilter} from '../dropdowns';
import {Button} from '../forms';
import {PaymentMethodIcon, paymentSourceMap} from '../icons';
import {displayPaymentSourceMap, displayPaymentStatusMap, paymentStatusToColor} from '../layout';
import {SlideOverWithBrandedHeader} from '../overlays';
import {ResetTableFiltersButton} from '../table';
import {CheckboxFilter} from './CheckboxFilter';
import {DashboardDatepicker} from './DashboardDatepicker';
import {EntityPickerFilter} from './EntityPickerFilter';
import {FlatPillWithDot} from './FlatPillWithDot';
import {InputSearchFilter} from './InputSearchFilter';

type Props<T extends PaymentsFilters> = {
  defaultFilters: T;
  typedSearchParams: T;
  setTypedSearchParams: (params: T) => void;
  selectedOrganization?: string;
  selectedMerchantAccount?: string;
  storeId?: string;
  organizationList?: {id: string; name: string}[];
  merchantAccounts?: {
    id: string;
    businessName: string;
    organizationId: string | null;
    status: MerchantAccountStatus | MerchantAccountPublicStatus;
    onboardedAt: string | null;
  }[];
  storeList?: {
    id: string;
    name: string;
    status: string;
  }[];
  paymentMethod?: string[];
  pspReferenceId?: string;
  authCode?: string;
  shopperId?: string;
  source?: string[];
  status?: string[];
  dateValue: {
    startDate: Date;
    endDate: Date;
  };
  customAttributesNames?: string[];
  handleDateChange: (dateRange: {
    startDate: Date;
    endDate: Date;
  }) => void;
  paymentMethodsFilters: string[];
  isPortal?: boolean;
  reference?: string;
};

export const PaymentFilters = <T extends PaymentsFilters>({
  defaultFilters,
  typedSearchParams,
  setTypedSearchParams,
  selectedOrganization,
  selectedMerchantAccount,
  merchantAccounts,
  storeId,
  storeList,
  paymentMethod,
  pspReferenceId,
  shopperId,
  source,
  authCode,
  status,
  dateValue,
  paymentMethodsFilters,
  organizationList,
  customAttributesNames,
  handleDateChange,
  isPortal,
  reference,
}: Props<T>) => {
  const {i18n} = useLingui();
  const [isOthersOpen, setIsOthersOpen] = useState(false);

  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({
        pageIndex: undefined,
        pageSize: undefined,
        [key]: value,
      } as unknown as T);
    },
    [setTypedSearchParams]
  );
  useEffect(() => {
    const isOrganizationMerchant = merchantAccounts
      ?.filter(merchant => merchant.organizationId === selectedOrganization)
      .some(merchant => merchant.id === selectedMerchantAccount);
    if (selectedOrganization && !isOrganizationMerchant) {
      setTypedSearchParams({selectedMerchantAccount: undefined} as unknown as T);
    }
  }, [setTypedSearchParams, selectedOrganization, merchantAccounts, selectedMerchantAccount]);

  useEffect(() => {
    if (!storeList) {
      return;
    }
    if (!storeList.some(store => store.id === storeId)) {
      setTypedSearchParams({storeId: undefined} as unknown as T);
    }
  }, [setTypedSearchParams, storeList, storeId]);

  const handleFilterChangeWithClear = useCallback(
    <K extends keyof T>(key: K, value: T[K]) => {
      setTypedSearchParams({
        pageIndex: undefined,
        pageSize: undefined,
        ...defaultFilters,
        [key]: value,
      } as unknown as T);
    },
    [setTypedSearchParams]
  );

  const handleMerchantChange = useCallback(
    (value: string | undefined) => {
      handleFilterChange('selectedMerchantAccount', value);
    },
    [handleFilterChange]
  );

  return (
    <div className="flex flex-wrap items-center justify-end gap-2 lg:flex-nowrap">
      <div className="block lg:hidden 2xl:block max-lg:w-full">
        <ResetTableFiltersButton
          ignoreKeys={[
            'endDate', // ignore endDate to show date range as a single applied date filter
            'pageIndex',
            'pageSize',
          ]}
          defaultFilters={{}}
          activeFilters={typedSearchParams}
          setFilters={setTypedSearchParams}
        />
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {organizationList && (
          <EntityPickerFilter
            selected={selectedOrganization || ''}
            onChange={value => handleFilterChange('selectedOrganization', value)}
            options={organizationList}
          />
        )}
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {!!merchantAccounts?.length && (
          <div className="font-normal shrink-0 max-lg:w-full">
            <MerchantAccountsPickerFilter
              selectedMerchantAccount={selectedMerchantAccount || ''}
              onSelectMerchantAccount={handleMerchantChange}
              merchantAccountsOptions={
                selectedOrganization
                  ? merchantAccounts.filter(
                      merchantAccount => merchantAccount.organizationId === selectedOrganization
                    )
                  : merchantAccounts
              }
              allLabel={i18n._('Merchant')}
            />
          </div>
        )}
      </div>
      <div className="font-normal shrink-0 max-lg:w-full">
        {!!storeList?.length && (
          <div className="font-normal shrink-0 max-lg:w-full">
            <EntityPickerFilter
              selected={storeId}
              label={'Store'}
              allLabel={'All Stores'}
              onChange={value => handleFilterChange('storeId', value)}
              options={storeList
                .sort((a, b) => (a.status === 'ACTIVE' ? -1 : b.status === 'ACTIVE' ? 1 : 0))
                .map(store => ({
                  name: store.name,
                  id: store.id,
                  ...(store.status !== 'ACTIVE' && {
                    className: 'text-gray-400',
                    classNameActive: 'text-gray-400',
                    tooltipText: i18n._('Store is not active'),
                  }),
                }))}
              className="w-full"
            />
          </div>
        )}
      </div>
      {paymentMethodsFilters.length > 0 && (
        <div className="flex-grow min-w-0 lg:hidden 2xl:block max-lg:w-full">
          <CheckboxFilter
            label={i18n._('Payment Method')}
            selected={paymentMethod}
            onChange={value => handleFilterChange('paymentMethod', value)}
            options={(paymentMethodsFilters ?? []).map(paymentMethod => ({
              element: <PaymentMethodIcon paymentType={paymentMethod} />,
              key: paymentMethod,
            }))}
          />
        </div>
      )}
      {customAttributesNames?.map(attributeName => (
        <div className="flex-grow min-w-0 lg:hidden max-lg:w-full" key={`attr-${attributeName}`}>
          <InputSearchFilter
            label={capitalizeCustomAttribute(attributeName)}
            selected={(typedSearchParams[attributeName as keyof T] as string) || ''}
            onChange={value => handleFilterChange(attributeName as keyof T, value as T[keyof T])}
          />
        </div>
      ))}
      {isPortal && (
        <div className="lg:hidden max-lg:w-full">
          <InputSearchFilter
            label={i18n._('Shopper ID')}
            selected={shopperId}
            onChange={value => handleFilterChangeWithClear('shopperId', value)}
          />
        </div>
      )}
      <div className="lg:hidden max-lg:w-full">
        <InputSearchFilter
          label={i18n._('Auth Code')}
          selected={authCode}
          onChange={value => handleFilterChangeWithClear('authCode', value)}
        />
      </div>
      <div className="lg:hidden max-lg:w-full">
        <InputSearchFilter
          label={i18n._('Merchant Reference ID')}
          selected={reference}
          onChange={value => handleFilterChange('reference', value)}
        />
      </div>
      <div className="max-lg:w-full">
        <InputSearchFilter
          label={i18n._('Transaction ID')}
          selected={pspReferenceId}
          onChange={value => handleFilterChangeWithClear('pspReferenceId', value)}
        />
      </div>
      <div className="lg:hidden 2xl:block max-lg:w-full">
        <CheckboxFilter
          label={i18n._('Source')}
          selected={source}
          onChange={value => handleFilterChange('source', value)}
          options={Object.entries(displayPaymentSourceMap).map(([key, value]) => ({
            element: (
              <div className="flex items-center gap-1 whitespace-nowrap">
                <span>{paymentSourceMap[key]}</span>
                {value}
              </div>
            ),
            key,
          }))}
        />
      </div>
      <div className="lg:hidden 2xl:block max-lg:w-full">
        <CheckboxFilter
          label={i18n._('Status')}
          selected={status}
          onChange={value => handleFilterChange('status', value)}
          options={(
            Object.keys(paymentStatusToColor) as Array<keyof typeof paymentStatusToColor>
          ).map(status => ({
            element: (
              <FlatPillWithDot
                color={paymentStatusToColor[status] || 'blue'}
                label={displayPaymentStatusMap[status] ?? status}
              />
            ),
            key: status,
          }))}
        />
      </div>
      <div
        className={
          !isPortal && !customAttributesNames?.length
            ? 'hidden lg:block max-lg:w-full 2xl:hidden'
            : 'hidden lg:block max-lg:w-full'
        }
      >
        <Button
          type="button"
          size="sm"
          onClick={() => setIsOthersOpen(true)}
          className="w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
        >
          {i18n._('Others')}
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
            <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </Button>
      </div>
      <div className="w-full mt-4 shrink-0 md:mt-0 lg:w-64">
        <DashboardDatepicker dateRange={dateValue} onChange={handleDateChange} />
      </div>
      <SlideOverWithBrandedHeader
        isOpen={isOthersOpen}
        title={i18n._('Others')}
        closeHandler={() => setIsOthersOpen(false)}
        panelClassName="w-screen pointer-events-auto lg:max-w-md"
      >
        <>
          {paymentMethodsFilters.length > 0 && (
            <div className="flex-grow min-w-0 2xl:hidden max-lg:w-full">
              <CheckboxFilter
                label={i18n._('Payment Method')}
                selected={paymentMethod}
                onChange={value => handleFilterChange('paymentMethod', value)}
                options={(paymentMethodsFilters ?? []).map(paymentMethod => ({
                  element: <PaymentMethodIcon paymentType={paymentMethod} />,
                  key: paymentMethod,
                }))}
              />
            </div>
          )}
          {customAttributesNames?.map(attributeName => (
            <div className="flex-grow min-w-0 max-lg:w-full" key={attributeName}>
              <InputSearchFilter
                label={capitalizeCustomAttribute(attributeName)}
                selected={(typedSearchParams[attributeName as keyof T] as string) || ''}
                onChange={value =>
                  handleFilterChange(attributeName as keyof T, value as T[keyof T])
                }
              />
            </div>
          ))}
          {isPortal && (
            <div className="max-lg:w-full">
              <InputSearchFilter
                label={i18n._('Shopper ID')}
                selected={shopperId}
                onChange={value => handleFilterChangeWithClear('shopperId', value)}
              />
            </div>
          )}
          <div className="max-lg:w-full">
            <InputSearchFilter
              label={i18n._('Auth Code')}
              selected={authCode}
              onChange={value => handleFilterChangeWithClear('authCode', value)}
            />
          </div>
          <div className="max-lg:w-full">
            <InputSearchFilter
              label={i18n._('Merchant Reference ID')}
              selected={reference}
              onChange={value => handleFilterChange('reference', value)}
            />
          </div>
          <div className="2xl:hidden max-lg:w-full">
            <CheckboxFilter
              label={i18n._('Source')}
              selected={source}
              onChange={value => handleFilterChange('source', value)}
              options={Object.entries(displayPaymentSourceMap).map(([key, value]) => ({
                element: (
                  <div className="flex items-center gap-1 whitespace-nowrap" key={`source-${key}`}>
                    <span>{paymentSourceMap[key]}</span>
                    {value}
                  </div>
                ),
                key,
              }))}
            />
          </div>
          <div className="2xl:hidden max-lg:w-full">
            <CheckboxFilter
              label={i18n._('Status')}
              selected={status}
              onChange={value => handleFilterChange('status', value)}
              options={(
                Object.keys(paymentStatusToColor) as Array<keyof typeof paymentStatusToColor>
              ).map(status => ({
                element: (
                  <FlatPillWithDot
                    color={paymentStatusToColor[status] || 'blue'}
                    label={displayPaymentStatusMap[status] ?? status}
                  />
                ),
                key: status,
              }))}
            />
          </div>
        </>
      </SlideOverWithBrandedHeader>
    </div>
  );
};
